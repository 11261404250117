<template>
    <div>
        <email-login />
    </div>
</template>
<script>
import EmailLogin from "./Form/EmailLogin";

export default {
    name: "UserLogin",
    components: { EmailLogin },
    props: {
        route: String
    }

}
</script>
<style scoped>

</style>