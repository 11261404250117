<template>
    <div>
        <b-row no-gutters>
            <b-col>
                <b-col class="news_header">
                    <h1>Предложить новость</h1>
                </b-col>
                <h4 class="news_back">
                    <router-link :to="{name:'NewsList'}">Назад</router-link>
                </h4>
                <div class="offer_card" v-if="$user">
                    <form class="form_add_form" @submit.stop.prevent>
                        <label for="text" class="label">Текст новости</label>
                        <div class="form_add_ipnut_box">
                            <textarea id="text" class="form_add_form_ipnut form_textarea" type="text" name="text" v-model="form.data[0].text"></textarea>
                            <span class="form_add_input_focus"></span>
                        </div>
                        <label for="my-strictly-unique-vue-upload-multiple-image" class="label">Добавить фотографии</label>
                        <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
                            <vue-upload-multiple-image :showPrimary="false" :markIsPrimaryText="option.markIsPrimaryText" :popupText="option.popupText" :primaryText="option.primaryText" :dropText="option.dropText" :browseText="option.browseText" :dragText="option.dragText" @upload-success="uploadImageSuccess" @before-remove="beforeRemove" @edit-image="editImage" @data-change="dataChange" v-model="image"></vue-upload-multiple-image>
                        </div>
                        <div class="form_add_button_box" v-if="img_preload">
                            <button @click="send()" class="form_add_button" :disabled="button_status">Отправить</button>
                        </div>
                        <div v-else class="d-flex justify-content-center w-100">
                            <PreLoadBox></PreLoadBox>
                        </div>
                    </form>
                </div>
                <div class="offer_card" v-else>
                    <div class="login" show>Авторизуйтесь, чтобы оставить комментарий.<br>
                        У вас еще нет аккаунта ?
                        <router-link :to="{name: 'UserRegistration',params: { city:$route.params.city }}">
                            Зарегистрироваться
                        </router-link>
                    </div>
                    <UserLogin />
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
/* eslint-disable no-console */
import VueUploadMultipleImage from 'vue-upload-multiple-image';
import PreLoadBox from "../../Preloader/coin";
import UserLogin from "@/components/App/User/UserLogin";

export default {
    name: "Offer",
    components: {
        VueUploadMultipleImage,
        PreLoadBox,
        UserLogin
    },
    data() {
        return {
            img_preload: 'finish',
            image: null,
            images: [],
            option: {
                markIsPrimaryText: '',
                dropText: 'Отпустите для загрузки',
                dragText: 'Перетащите фото',
                browseText: 'Или нажмите',
                popupText: 'Изображение по умолчанию',
                primaryText: '',

            },
            form: {
                data: [{
                    title: 'Предложенная новость',
                    text: '',
                    created_author: 'Предложенная новость',
                    published: 0,
                    cat_id: 0,
                    source_url: 'Предложенная новость',
                    url_youtube: 'https://youtu.be/SeZ6N2boEbU',
                    images: []
                }],
                strong_validation: 1,
            },
            button_status: false,
        }

    },
    methods: {
        send() {

            if (this.form.data[0].text.length < 1 && this.form.data[0].images.length < 1) {
                this.$notify({
                    group: 'error',
                    type: 'error',
                    text: 'Добавьте новость или фотографию',
                    title: 'Ошибка!',
                    duration: 5000,
                });
            } else {
                if (this.form.data[0].text.length < 1) {
                    this.form.data[0].text = 'Предложенная новость';
                }
                this.button_status = true;
                let url = this.$config.api_url + this.$route.params.city + '/News.set';

                this.$http.post(url, this.form).then((response) => {
                    //this.formReset();
                    console.log(response.status);
                }).finally(() => {
                    this.$notify({
                        group: 'error',
                        type: 'success',
                        text: 'Ваша информация отправленна на модерацию',
                        title: 'Спасибо',
                        duration: 5000,
                    });
                    setTimeout(() => {
                        this.$router.push({ name: 'NewsList' });
                    }, 5000);

                });

            }

        },
        formReset() {
            this.form.text = '';
        },
        uploadImageSuccess(formData, index, fileList) {
            this.img_preload = null;
            console.log(fileList, index);
            let url = this.$config.api_url + this.$route.params.city + '/Storage.setImages';
            this.$http.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {

                this.form.data[0].images.push(response.data.response[0].path);
                this.img_preload = 'finish';
            });
        },
        beforeRemove(index, done, fileList) {
            console.log(fileList);
            var r = confirm("Удалить изображение?")
            if (r == true) {
                this.form.data[0].images.splice(index, 1);
                done()
            }
        },
        editImage(formData, index, fileList) {
            this.img_preload = null;
            console.log(fileList);
            let url = this.$config.api_url + this.$route.params.city + '/Storage.setImages';
            this.$http.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                this.form.data[0].images.splice(index, 1, response.data.response[0].path);
                this.img_preload = 'finish';
            });

        },
        dataChange(data) {

            console.log(data)
        },
    },
    watch: {
        'image': function() {
            if (this.image) {
                console.log(this.image);
            }
        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 576px) {
    .offer_card {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

@media only screen and (max-width: 991px) {
    .right_menu {
        display: none;
    }
}

@media only screen and (max-width: 450px) {
    .login {
        font-size: 12px;
    }
}

@media only screen and (min-width: 451px) and (max-width: 767px) {
    .login {
        font-size: 14px;
    }
}

@media only screen and (min-width: 768px) {
    .login {
        font-size: 16px;
    }
}
.card {
    border-radius: 12px;
    margin-bottom: 24px;
}
.login {
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.5rem;
    margin-bottom: 10px;
}

.login a {
    text-decoration: none;
}

textarea {
    outline: none;
    border: none;
    min-height: 100px;
}

textarea:focus::-webkit-textarea-placeholder {
    color: transparent;
}

textarea::-webkit-textarea-placeholder {
    color: #999999;
}

input {
    outline: none;
    border: none;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

input::-webkit-input-placeholder {
    color: #999999;
}

button:hover {
    cursor: pointer;
}

.news_container {
    margin: 20px auto;
}

.news_header {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 15px 0;
    text-transform: uppercase;
    margin-bottom: 15px;

}

.news_back {
    width: 75px;
    padding: 5px 5px;
    position: relative;
    background: #4a76a8;
    border-radius: 0.25rem;
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 14px;
}

.news_back:before {
    content: "";
    border: solid transparent;
    position: absolute;
    top: 5px;
    right: 100%;
    border-right-color: #4a76a8;
    border-width: 7px;
    margin-right: -1px;
}

.news_back a {
    text-decoration: none;
    color: #fff;
}

.offer_card {
    padding: 20px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
}

#my-strictly-unique-vue-upload-multiple-image {
    width: 100%;
}

#my-strictly-unique-vue-upload-multiple-image>div {
    width: 100% !important;
}

#my-strictly-unique-vue-upload-multiple-image .image-container {
    width: 100% !important;
}
</style>