<template>
    <div>
        <div v-if="!$user">
            <form @submit.prevent="login">
                <label for="email" class="main_input_label">E-mail</label>
                <input id="email" type="text" class="main_input" v-model="form.email" required>
                <label for="password" class="main_input_label">Пароль</label>
                <input id="password" type="password" class="main_input" v-model="form.password" required>
                <button class="button button_default" type="submit">Войти</button>
                <router-link :to="{name: 'PasswordReset'}">
                    <div class="pas_res" @click="callParent">Забыли пароль? </div>
                </router-link>
            </form>
        </div>
        <div v-else>
            <div class="user_box">
                <span>{{$user.name}} {{$user.f_name}}</span>
                <div class="border_line"></div>
                <router-link :to="{name: 'UserGet'}" class="menu_item">Редактировать</router-link>
                <div class="border_line"></div>
                <span class="menu_item" @click="loginOut">Выйти</span>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    props: {
        dropdown: Object
    },
    name: "EmailLogin",
    data() {
        return {
            form: {
                email: '',
                password: ''
            },
        }
    },
    methods: {
        callParent() {
            this.$emit('onChange');
        },
        api(email, pass) {
            let url = this.$config.api_url + 'User.emailLogin';
            this.$http.post(url, { email, pass }).then((response) => {
                this.$user = response.data.response.item;
                this.$notify({
                    group: 'error',
                    type: 'success',
                    text: 'Мы рады, что Вы снова зашли к нам',
                    title: 'С возвращением!',
                    duration: 5000,
                });
            }).catch((error) => {
                this.$user = null;
                this.$notify({
                    group: 'error',
                    type: 'error',
                    text: error,
                    title: 'Ошибка!',
                    duration: 5000,
                });
            })
        },
        login() {
            this.api(this.form.email, this.form.password);
            this.$router.push({ name: 'Home' });

        },
        loginOut() {
            this.$user = null;
        },

    }
}
</script>
<style scoped>
.btn {
    background: #4a76a8;
    border-color: #4a76a8;

}

.btn:hover {
    background: #28a745;
    color: #fff;
    border-color: #28a745;

}

.user_box span {
    display: block;
    text-align: left;
    height: 30px;
    white-space: nowrap;
    position: relative;
    line-height: 30px;
    padding: 0 15px;
}

.menu_item:hover {
    cursor: pointer;
    background: #4a76a8;
    color: #fff;
}


.border_line {
    margin: 4px 13px;
    border-top: 1px solid #dbe4ec;
}

.pas_res {
    font-weight: bold;
    padding: 20px 0;
    color: rgba(0, 0, 0, .6);
}
</style>